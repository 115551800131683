<script setup>
import {
  BuildingStorefrontIcon,
  FaceSmileIcon,
  GlobeAltIcon,
} from "@heroicons/vue/24/outline";

const supabase = useSupabaseClient();

const { insertCouponRedeem } = useCouponRedeemManager();

const props = defineProps({
  coupons: { type: Array, required: true },
});

const loading = ref(false);
const shownConditionsIndex = ref(null);
const couponIndex = ref(null);

// const showConditionsByIndex = (index) => {
//   shownConditionsIndex.value = index;
// };

const remainingDays = computed(() => {
  return props.coupons.map((coupon) => {
    const endDate = new Date(coupon.end_date);
    const now = new Date();
    const diffTime = endDate - now;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  });
});

function generateCode() {
  const now = new Date();
  const year = now.getFullYear();
  const yeatLastTwoDigits = String(year).slice(-2);
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");

  const timestamp = `${yeatLastTwoDigits}${month}${day}${hours}${minutes}${seconds}`;
  const randomString = Math.random().toString(36).substr(2, 4);

  return `${randomString}-${timestamp}`;
}

const checkCodeExists = async (code) => {
  const { data, error } = await supabase
    .from("coupon_redeems")
    .select("*")
    .eq("redeem_code", code);

  if (error) {
    throw error;
  }

  return data.length > 0;
};

const handleCouponRedeem = async (
  couponId,
  index,
  redemptionType,
  redemptionLink,
) => {
  try {
    loading.value = true;
    couponIndex.value = index;
    let unique = false;
    let code;
    while (!unique) {
      code = generateCode();
      const codeExists = await checkCodeExists(code); // You need to implement this function
      if (!codeExists) {
        unique = true;
      }
    }
    const dataToInsert = {
      coupon_id: couponId,
      redeem_code: code,
      request_count: 1,
    };
    await insertCouponRedeem(dataToInsert);

    redemptionType === "local"
      ? navigateTo(`/web/cupon/version-imprimible/${code}`, {
          external: true,
        })
      : navigateTo(redemptionLink, {
          external: true,
        });
  } catch (error) {
    console.error(error);
  }
};

const promo = computed(() => {
  return props.coupons.map((coupon) => {
    return coupon.discount_amount
      ? `$${coupon.discount_amount
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
      : coupon.discount_percentage
      ? `${coupon.discount_percentage
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}%`
      : coupon.another_promo_type;
  });
});

const startDate = computed(() => {
  return props.coupons.map((coupon) => {
    return getFormattedDate(coupon.start_date, false);
  });
});

const endDate = computed(() => {
  return props.coupons.map((coupon) => {
    return getFormattedDate(coupon.end_date, false);
  });
});

const logo = computed(() => {
  return props.coupons.map((coupon) => {
    return coupon.businesses && coupon.businesses.business_logo
      ? coupon.businesses.business_logo
      : coupon.business_logo;
  });
});

function countCharacters(str, num) {
  return str.length >= num;
}

watchEffect(() => {
  console.log(props.coupons);
});
</script>

<template>
  <li v-for="(coupon, index) in coupons" :key="coupon.coupon_id">
    <div class="relative w-full rounded-2xl bg-slate-200 object-cover">
      <BuildingStorefrontIcon
        v-if="coupon.redemption_type === 'local'"
        class="absolute left-0 top-0 m-2 h-4 w-4 text-slate-400"
      />
      <GlobeAltIcon
        v-if="coupon.redemption_type === 'online'"
        class="absolute left-0 top-0 m-2 h-4 w-4 text-gray-400"
      />

      <div class="grid h-full grid-cols-4">
        <div class="col-span-3 justify-center p-3 text-center">
          <div v-if="shownConditionsIndex !== index">
            <div class="flex flex-col">
              <div class="mb-1 pl-3 pt-1">
                <img
                  class="inline-block h-10 w-10 rounded-full border-2 border-white"
                  :src="logo[index]"
                  alt="logo"
                />
              </div>
              <div
                :class="[
                  `mb-2 font-sans font-semibold leading-5 text-custom-fuchsia-07e sm:whitespace-nowrap`,
                  countCharacters(coupon.title, 20) ? `text-base` : `text-lg`,
                  coupon.another_promo_type &&
                  countCharacters(coupon.another_promo_type, 10)
                    ? 'mt-1.5'
                    : 'mt-0',
                ]"
              >
                {{ coupon.title }}
              </div>
              <div
                :class="[
                  `font-sans  font-bold leading-6 text-custom-calypso-fe3`,
                  coupon.another_promo_type &&
                  countCharacters(coupon.another_promo_type, 10)
                    ? `-mt-1.5 text-2xl`
                    : `text-4xl`,
                ]"
              >
                {{ promo[index] }}
              </div>
              <div
                :class="[
                  `font-sans font-bold leading-6 text-custom-calypso-fe3`,
                  coupon.another_promo_type && 'h-6',
                ]"
              >
                {{ !coupon.another_promo_type ? "de descuento" : "  " }}
              </div>
              <div class="mt-2 text-[10px] uppercase text-gray-500">
                Válido hasta el
                <span class="font-bold">{{ endDate[index] }}</span>
              </div>
              <button
                class="text-[8px] font-semibold text-gray-600"
                @click="shownConditionsIndex = index"
              >
                Ver condiciones
              </button>
            </div>
          </div>
          <div v-else class="mx-5">
            <h4 class="mx-auto mb-1 mt-3">Condiciones</h4>
            <ul class="mx-auto h-24 text-left text-[10px]">
              <li>
                Válido desde el
                {{ startDate[index] }} al
                {{ endDate[index] }}
              </li>
              <li>
                {{ maxRedeemsPerUser }}
              </li>
              <li>
                {{ maxTotalRedeems }}
              </li>
              <li>
                {{ coupon.conditions }}
              </li>
            </ul>
            <button
              class="text-[8px] font-semibold text-gray-600"
              @click="shownConditionsIndex = null"
            >
              Ver promo
            </button>
          </div>
        </div>
        <div
          class="border-custom relative rounded-r-2xl border-l-2 border-dashed bg-slate-300 p-3"
        >
          <div
            class="absolute -left-[3px] top-0.5 ml-[-10px] flex h-6 w-6 -translate-y-1/2 transform items-center justify-center rounded-full bg-white text-white"
          >
            l
          </div>
          <div
            class="absolute -bottom-[22px] -left-[3px] ml-[-10px] flex h-6 w-6 -translate-y-1/2 transform items-center justify-center rounded-full bg-white text-white"
          >
            l
          </div>
          <div class="flex h-full w-full flex-col justify-center">
            <div class="my-auto flex flex-col text-center text-[10px]">
              <div class="text-center leading-tight">
                <div class="text-xs text-gray-500">
                  {{ remainingDays[index] === 1 ? "Queda" : "Quedan" }}
                </div>
                <div class="-my-1.5 text-2xl font-bold text-custom-calypso-fe3">
                  {{ remainingDays[index] }}
                </div>
                <div class="text-xs text-gray-500">
                  {{
                    remainingDays[index] <= 5 && remainingDays[index] > 1
                      ? "días!"
                      : remainingDays[index] === 1
                      ? "día!"
                      : "días"
                  }}
                </div>
              </div>

              <div
                v-if="coupon.redemption_type === 'online'"
                class="mt-3 flex flex-col"
              >
                <span
                  class="mb-0.5 whitespace-nowrap rounded-full bg-slate-400 px-3 text-white"
                  >Código</span
                >
                <span
                  class="font-bold uppercase tracking-tighter text-custom-fuchsia-07e"
                  >{{ coupon.coupon_code }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mx-1 grid grid-cols-2 py-2">
      <button
        type="button"
        class="relative flex w-3/4 items-center justify-center rounded-md border-2 border-dashed border-slate-400 bg-slate-50 px-2 py-0.5 text-xs font-medium text-gray-400 hover:border-slate-400 hover:text-gray-500"
        @click="
          handleCouponRedeem(
            coupon.coupon_id,
            index,
            coupon.redemption_type,
            coupon.redemption_link,
          )
        "
      >
        {{
          loading && couponIndex === index
            ? "Procesando..."
            : coupon.redemption_type === "local"
            ? "Generar código"
            : "Ir a la web"
        }}
      </button>

      <div class="flex flex-row justify-end py-1">
        <div v-if="coupon.businesses">
          <a :href="`/web/comercio/${coupon.businesses.business_id}`">
            <div
              class="-mt-0.5 text-xs font-semibold text-gray-500 hover:text-gray-600 hover:underline"
            >
              <div class="flex whitespace-nowrap">
                <!-- <BuildingStorefrontIcon
                  class="mr-0.5 h-4 w-4 hover:text-gray-600"
                /> -->
                Por {{ coupon.businesses.business_display_name }}
              </div>
            </div>
          </a>
        </div>
        <!-- <div class="group relative">
          <div class="flex">
            <ShareIcon class="ml-1 mt-[1px] h-4 w-4 text-gray-500" />
            <div class="mt-0.5 text-[10px] text-gray-500">12</div>
          </div>
          <div
            class="absolute bottom-5 left-0 rounded-sm bg-gray-500 px-1 text-[8px] text-white opacity-0 transition-opacity duration-200 group-hover:opacity-100"
          >
            Disponibles
          </div>
        </div> -->
        <div class="group relative">
          <div class="flex">
            <FaceSmileIcon class="-mt-1 ml-2 h-5 w-5 text-custom-fuchsia-07e" />
            <div class="-mt-0.5 text-[12px] text-gray-500">
              {{ coupon?.coupon_redeems.length }}
            </div>
          </div>
          <div
            class="absolute bottom-5 right-0 whitespace-nowrap rounded-sm bg-gray-500 px-1 text-[8px] text-white opacity-0 transition-opacity duration-200 group-hover:opacity-100"
          >
            Usuarios canjeando
          </div>
        </div>
      </div>
    </div>
  </li>
</template>
